@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  background: #fff;
  height: 100vh;
  font-family: "Space Grotesk", sans-serif;
}


.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}


.grey {
  color: #888 !important;
}



.ptb20 {
  padding: 20px 0;
}




.ptb60 {
  padding: 60px 0;
}


iframe {
  // display: none !important;
  z-index: -9 !important;
}

#zkme-widget {
  width: 510px;
  height: 700px;
  border: 2px solid #005563;
  border-radius: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-notallowed {
  cursor: not-allowed;
}

.purple {
  color: #6c01c3;
}

.yellow {
  color: #ffc13d;
}

.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}

.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}


@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .metamaskHide {
    display: none !important;
  }
}

// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }

}



// iframe {
//   position: unset !important;
//   top: 0px !important;
//   left: 0px !important;
//   width: 0px !important;
//   height: 0px !important;
// }

.recaptcha-wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 30px auto 0 auto;
}


button {
  cursor: pointer;
}

@media (max-width: 600px) {
  h2 {
    font-size: 30px !important;
  }

  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}


@media(max-width:600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;

}

button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;

}

button.close {
  opacity: 1;
}


.custom-container {
  max-width: 1270px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.material-textfield {
  position: relative;
  width: 100%;
}

.material-textfield label {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  padding: 0 6.4rem 0 1.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  color: #343434;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 90%;
}

.material-textfield input {
  outline: none;
  transition: 0.1s ease-out;
  border: 1px solid #fff;
  background: #000;
  border-radius: 0px;
  padding: 17px 18px;
  width: 100%;
  box-shadow: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 117%;

  &::placeholder {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;
  }
}

.material-textfield input:focus+label {
  color: #fff;
  top: 0;
  left: 6px;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

.material-textfield input:not(:placeholder-shown)+label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  padding: 0 0.3rem;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: unset;
}

.modal-backdrop {
  opacity: unset !important;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);
}

.capcha {
  iframe {
    width: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;

  }

}



.recaptchaframe {
  div {
    iframe {
      display: block !important;
      z-index: 9 !important;
    }
  }
}

.connectwallet-modal {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(4px);

  .modal-dialog {
    max-width: 500px;
  }

  .modal-content {
    border-radius: 12px;
    background: #FFF;
    padding: 20px;
  }

  .modal-header {
    border-radius: 12px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
    padding: 15px;
    justify-content: space-between;

    .modal-title {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    .btn-close {
      background: url("../src/assets/close-circle.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      border: none;
      box-shadow: none;
      padding: 0;
      outline: none;
      opacity: unset;
      width: 24px;
      height: 24px;
      margin: 0;
    }
  }

  .modal-body {
    padding: 0px;

    .darktext {
      background: #080808;
      padding: 20px 10px 10px 20px;
      margin-top: 15px;
      margin-bottom: 30px;

      .scrolltext {
        height: 380px;
        overflow: auto;
        padding-right: 5px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #2B2A2A;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #FFF;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #FFF;
        }

        .formarginleft {
          margin-left: 30px;
        }

        .darkpara {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 117%;
          margin-bottom: 14px;

          .bold {
            font-weight: 700;
          }

          .headingtext {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 117%;
          }

          .formarginleft {
            margin-left: 30px;
          }
        }

        .darkparaupper {
          color: #FFF;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 117%;
          margin: 14px 0px;
        }
      }
    }

    .checkboxmain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;

      .clear {
        clear: both;
      }

      .checkBox {
        display: block;
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 3px solid rgba(255, 255, 255, 0);
        position: relative;
        overflow: hidden;
        box-shadow: 0px 0px 0px 2px #FFF;
      }

      .checkBox div {
        width: 60px;
        height: 60px;
        background-color: #FFF;
        top: -52px;
        left: -52px;
        position: absolute;
        transform: rotateZ(45deg);
        z-index: 100;
      }

      .checkBox input[type=checkbox]:checked+div {
        left: -10px;
        top: -10px;
      }

      .checkBox input[type=checkbox] {
        position: absolute;
        left: 50px;
        visibility: hidden;
      }

      .transition {
        transition: 300ms ease;
      }

      .checkboxpara {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 117%;
      }
    }

    .continuebutton {
      border: 1px solid #FFF;
      background: #000;
      box-shadow: 4px 4px 0px 0px #FFF;
      display: flex;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      margin-top: 25px;
    }

    .disablebutton {
      display: flex;
      padding: 15px 40px;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.05);
      color: #363636;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      width: 100%;
      border: none;
      margin-top: 25px;
    }

    .twice-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      gap: 15px;

      button {
        background-color: transparent;
        border: 1px solid #242424;
        background: #000;
        height: 130px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.3s linear;
        gap: 13px;
        padding: 40px;

        h6 {
          color: #FFF;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &:hover {
          border: 1px solid #FFF;
          background: #000;
          box-shadow: 2px 2px 0px 0px #FFF;
        }
      }
    }

    .buy-modal {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 30px;

      p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; 
        text-align: center;
        margin: 20px 0px 30px;

        span {
          font-weight: 700;
        }
      }

      .btn-common {
        border-radius: 15px;
        background: #53FFEA;
        box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
        border: none;
        display: flex;
        padding: 19px 40px;
        justify-content: center;
        align-items: center;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; 
        text-transform: uppercase;
        width: 100%;
      }

    }
  }
}

.buy-modal {
  .modal-dialog {
    max-width: 508px;
  }
}