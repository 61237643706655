.mainheader {
    border-bottom: 1px solid #EDEDED;
    background: #212121;
    backdrop-filter: blur(12px);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    .innerheader {
        padding: 16px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .disconnectbtn {
            color: #FE0000;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.015px;
            border: 1px solid #FE0000;
            background: #000;
            display: flex;
            padding: 10px 15px;
            justify-content: center;
            align-items: center;
        }
    }
}


.mainheader{
    .twice-btn{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 45px;
    }
}
.faq-btn {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}