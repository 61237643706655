.main-banner {
    min-height: 100vh;
    padding: 116px 15px 50px;

    .innerbanner {
        max-width: 607px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .bannerhead {
            color: #000;
            text-align: center;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 10px;
        }

        .bannerpara {
            color: #636363;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 40px;
        }

        .connectwalletpara {
            margin-bottom: 25px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          
        }

        .walletmain {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
            width: 100%;

            .walletinner {
                border-radius: 12px;
                border: 1px solid #F2F2F2;
                background: #FFF;

                display: flex;
                padding: 40px 20px;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                gap: 12px;
                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    border-radius: 12px;
                    border: 1px solid #E6E6E6;
                    background: #FFF;
                    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                    transition: 0.3s;
                }

                .walletupperpara {
                    color: rgba(0, 0, 0, 0.40);
                    text-align: center;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .walletname {
                    color: #000;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}

.cannotmodal {
    .modal-dialog {
        max-width: 546px;

        .modal-content {
            border: 1px solid #FFF;
            background: #000;
            box-shadow: 4px 4px 0px 0px #FFF;
            border-radius: 0px;
            padding: 0px;

            .modal-body {
                padding: 38px 46px;

                .redhead {
                    color: #F00;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    margin-bottom: 17px;
                }

                .dullpara {
                    color: #C9C9C9;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }
    }
}

@media (max-width:600px) {
    .main-banner .innerbanner .bannerpara {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .main-banner .innerbanner .bannerhead {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .main-banner .innerbanner .votingtimer {
        padding: 20px;
        margin-bottom: 30px;
    }

    .main-banner .innerbanner .votingtimer .votingtimerhead {
        font-size: 12px;
    }

    .main-banner .innerbanner .votingtimer .timerspans .maintime {
        width: 46px;
        height: 40px;
        gap: 3px;
    }

    .main-banner .innerbanner .votingtimer .timerspans .maintime .innertimer {
        width: 29px;
        height: 29px;

        .innertimertext {
            font-size: 12px;
        }
    }

    .main-banner .innerbanner .votingtimer .timerspans .maintime .timertext {
        font-size: 7px;
    }

    .main-banner .innerbanner .votingtimer .timerspans {
        gap: 7px;
    }

    .main-banner .innerbanner .walletmain {
        flex-direction: column;
    }

    .cannotmodal .modal-dialog .modal-content .modal-body {
        padding: 38px 20px;
    }

    .cannotmodal .modal-dialog .modal-content .modal-body .redhead {
        font-size: 18px;
    }

    .cannotmodal .modal-dialog .modal-content .modal-body .dullpara {
        font-size: 14px;
        line-height: 140%;
    }
    .main-banner .disclaimermain{
        margin-top: 30px;
        .disclaimerhead{
            font-size: 16px;
        }
    }
}